import React, { useState, useEffect, Suspense } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import Loading from './Loading';
import '../styles/Resume.css';

function Resume({ onLoad }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isLoading, setIsLoading] = useState(true);
  // We'll store the dynamically imported Spline component here
  const [SplineComponent, setSplineComponent] = useState(null);

  useEffect(() => {
    const handleResize = debounce(() => {
      const mobileCheck = window.innerWidth <= 768;
      setIsMobile(mobileCheck);

      // If the user resizes from mobile -> desktop, we can load the Spline component then
      if (!mobileCheck && !SplineComponent) {
        import('@splinetool/react-spline').then((module) => {
          setSplineComponent(() => module.default);
        });
      }
    }, 300);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [SplineComponent]);

  useEffect(() => {
    // Only load the Spline component if the user is on desktop initially
    if (!isMobile) {
      import('@splinetool/react-spline').then((module) => {
        setSplineComponent(() => module.default);
      });
    }
  }, [isMobile]);

  const handleLoad = () => {
    setIsLoading(false);
    if (onLoad) {
      onLoad();
    }
  };

  // If it’s mobile, skip loading the 3D scene altogether
  if (isMobile) {
    return (
      <div className="resume-wrapper">
        <div className="mobile-resume-message">
          {/* or maybe a scaled-down image, or an alternate design */}
          <h2>Mobile View Detected</h2>
          <p>3D scene is disabled on small screens.</p>
        </div>
      </div>
    );
  }

  // For non-mobile, render the Spline scene once it’s dynamically imported
  return (
    <div className="resume-wrapper">
      {isLoading && (
        <div className="loading-overlay">
          <Loading />
        </div>
      )}
      <Suspense fallback={<div><Loading /></div>}>
        {SplineComponent && (
          <SplineComponent
            scene="https://prod.spline.design/a8XWcvvru432-wPB/scene.splinecode"
            onLoad={handleLoad}
            className="spline-desktop"
            style={{
              display: isLoading ? 'none' : 'block',
              width: '100%',
              height: '100%'
            }}
          />
        )}
      </Suspense>
    </div>
  );
}

Resume.propTypes = {
  onLoad: PropTypes.func,
};

export default React.memo(Resume);
